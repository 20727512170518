<template>
  <div id="home" class="md:pt-64 pt-16 bg-white md:pb-164px pb-16">
    <div class="max-w-1200px w-full mx-auto">
      <div class="flex justify-between items-center relative px-4 md:px-0">
        <div class="z-10 mt-148px md:mt-0">
          <div class="font-Roboto-Bold md:text-48px text-36px text-scp-black">
            <div>
              Building Web3
            </div>
            <div>
              Off-chain Dapp with SCP on Arweave
            </div>
          </div>
          <div class="md:text-xl md:leading-8 text-14px font-Roboto-Medium mt-6 text-scp-black2">
            <div>
              Unlocking the Power of Consensus Data
            </div>
            <div>
              Investing in the Future of the Decentralized and Permanent Web
            </div>
          </div>
        </div>
        <div class="md:min-w-400px md:w-400px md:ml-55px w-272px min-w-272px absolute md:relative md:right-0 md:top-0 right-4  top-6">
          <div style="width: 236px;height: 164px;mix-blend-mode: color-dodge;" class="opacity-60 bg-scp-white absolute bottom-0 rounded-tr-lg md:hidden block" />
          <img src="@/images/scp_head_banner.png" alt="scp_head_banner">
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang='ts'>

</script>

<style></style>
