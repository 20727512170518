<template>
  <div id="footer" class="w-full bg-scp-black4 flex items-center py-8 md:py-0 md:h-100px px-4 md:px-0">
    <div class="max-w-1200px w-full mx-auto flex justify-between md:items-center items-start md:flex-row flex-col">
      <img src="@/images/arscp_logo_white.png" class="h-8 md:h-9" alt="arweave_scp">
      <div class="flex sm:items-center items-start sm:flex-row flex-col font-Roboto-Regular text-sm sm:text-base sm:leading-none text-white mt-8 md:mt-0 w-full md:w-auto">
        <div class="whitespace-nowrap">
          Contact Us
        </div>
        <div class="flex sm:items-center justify-start  mt-4 sm:mt-0 w-full sm:leading-normal leading-10">
          <div class="sm:h-6 h-5 flex items-center sm:mx-8  mr-6 ">
            <img src="@/images/email.png" class="h-full mr-2" alt="email"> arscp@ever.vision
          </div>
          <a class="sm:h-6 h-5 flex items-center" href="https://twitter.com/ArweaveSCP" target="_blank">
            <img src="@/images/twitter.png" class="h-full mr-2" alt="twitter"> @ArweaveSCP
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang='ts'>

</script>

<style></style>
